@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,300|Kanit:regular,bold|Dosis:regular,bold);
html {
  font-size: calc((1vw + 1vh) * 1.45);
  font-family: 'Source Sans Pro', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: #14131e;
  color: white;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

#root {
  background-image: url(/static/media/laTuque_bkg1.ff17e2f9.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

html, body, #root {
  margin: 0;
  height: 100%;
  padding: 0;
  overflow: hidden;
}


@media screen and (min-width: 1025px) {
  html {
    font-size: calc((1vw + 1vh) * 0.7);
  }

  body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  #root {
    width: 42vw;
    border: solid 1px #f53331;
    border-bottom: none;
    border-top: none;
  }
}

p {
  margin: 0;
  line-height: 1.3;
}

::-webkit-scrollbar {
  width: 0px;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}

h1 {
  font-size: 2.1rem;
}

